
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');


body, html {
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
  margin: 0;
  background-color: #CCC5B7 !important;
}
h1, h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: #6F5D44;
}

h3, h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #6F5D44;
}

button, .cta-text {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

body, p, li, a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.nav-link.active {
  font-weight: bold;
  color: #007bff !important; /* Cambiar el color del enlace activo */
  border-bottom: 2px solid #007bff; /* Línea inferior para resaltar el activo */
}

.navbar-brand img {
  height: 60px;
  margin: 10px;
}

.nav-link:hover {
  font-weight: 700;
  color: #007bff !important; /* Hacer que el texto se destaque cuando se pasa el ratón */
}

.nav-link {
  margin-right: 15px; /* Aumentar espacio entre los links */
}

.nav-icon {
  color: #36362f; /* Color oscuro para íconos */
  transition: color 0.3s ease; /* Animación para cuando el ícono se destaque */
}

.nav-link {
  transition: all 0.3s ease;
  color: rgb(146, 135, 135);
}

.nav-icon.active{
  color: rgb(146, 135, 135) !important;
  border-bottom: none !important;
}

.nav-icon:hover {
  color: #007bff !important;
  transform: scale(1.25); /* Un pequeño aumento al pasar el ratón */
}

.footer {
  bottom: 0;
  width: 100%;
}


.artist-box {
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 16px; /* Reducir tamaño de texto */
  
}

.add-artist-container:hover {
  background-color: #746464;
}

.add-artist-container{
  width: 300px;
  height: 300px;
  cursor: pointer;
  display: flex;
  transition: background-color 0.3s ease; 
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #aaa;
  border-radius: 10px;
}

.add-artist-icon {
  font-size: 48px;
  color: #ccc;
}

.artist-image-container{
  width: 300px;
  height: 300px;
  text-align: center;
  margin-top: 0;
  overflow: hidden;
}

.artist-info {
  text-align: left;
  padding: 10px;
}

.artist-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 10px;
  object-fit: cover;
}

.artist-details {
  font-size: 14px; /* Reducir tamaño del texto en los detalles */
}

.artist-details p {
  margin: 5px 0;
  line-height: 1.4;
}

.artist-details ul {
  list-style-type: none;
  padding: 0;
}

.artist-details ul li {
  margin: 5px 0;
}

.artist-details a {
  color: #1db954;
  text-decoration: none;
}

.artist-details a:hover {
  text-decoration: underline;
}

.add-artist-btn {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.add-artist-btn:hover {
  background-color: #218838;
}

.artist-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
